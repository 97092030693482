'use strict';
(function (window, $) {
    var $notifyMeForm = $('form.jwsdw-notifyme-form'),
        $submitButton = $notifyMeForm.find('.jwsdw-notifyme-submit-container button'),
        $formErrorMessage = $notifyMeForm.find('.jwsdw-notificationFormErrorMessage'),
        $submitButtonText = $submitButton.find('.jwsdw-buttonText'),
        $submitButtonSpinner = $submitButton.find('.jwsdw-spinner'),
        xhr;

    $.validator.setDefaults({
        'rules': {
            'email': {
                'invalidvalue': true,
                'customemail': true
            }
        }
    });

    // initialize form validation
    $notifyMeForm.validate();

    /**
     * Method to handle form submits
     * @param {Object} event the event that triggered the handler
     * @param {function} successCallback the callback that is triggered when the ajax call was successful
     * @param {function} errorCallback the callback that is triggered when the ajax call was unsuccessful
     * @param {function} beforeSendCallback the callback that is triggered before the ajax call is made
     * @param {function} completeCallback the callback that is triggered after the ajax call was made
     * @returns {void}
     */
    function formSubmit (event, successCallback, errorCallback, beforeSendCallback, completeCallback) {
        var $this = $(this),
            $captcha = $this.find('.g-recaptcha');

        var validateCaptcha = $captcha.length > 0;

        event.preventDefault();

        // only submit form if frontend validation is successful and no other call is executed
        if (!$this.valid() || (xhr && xhr.readyState !== 4)) {
            return;
        }

        if (window.jwsdwRecaptchaSettings.captchaDisabled === false && validateCaptcha === true) {
            if (!window.grecaptcha || window.jwsdwUtil.captcha.getResponse($captcha.attr('id')).length !== 0) {
                return;
            }
            window.jwsdwUtil.captcha.execute($captcha.attr('id')).then(submitFormHandler);
        } else {
            submitFormHandler();
        }

        /**
         * @function
         * @description Handler to trigger submit again when form is valid
         * @return {void}
         */
        function submitFormHandler() {
            if (window.jwsdwRecaptchaSettings.captchaDisabled === false &&
                validateCaptcha === true && window.jwsdwUtil.captcha.getResponse($captcha.attr('id')).length === 0) {
                // no response yet, check again in 200ms
                setTimeout(submitFormHandler, 200);
                return;
            }

            // XHR request to form action url with form fields as parameters
            xhr = $.ajax($this.attr('action'), {
                'method': $this.attr('method') || 'GET',
                'data': $this.serialize(),
                'beforeSend': beforeSendCallback,
                'success': successCallback,
                'error': errorCallback,
                'complete': completeCallback
            });
        }
    }

    /**
     * Method to execute the before send logic before the ajax call is made
     * @returns {void}
     */
    function notifyMeBeforeSendCallback () {
        $formErrorMessage.addClass('jws-hidden');
        $submitButton.attr('disabled', 'disabled');
        $submitButtonText.addClass('jwsdw-invisible');
        $submitButtonSpinner.removeClass('jws-hidden');
    }

    /**
     * Method to execute after the  ajax call was made
     * @returns {void}
     */
    function notifyMeCompleteCallback () {
        var $captcha;
        if (window.jwsdwRecaptchaSettings.captchaDisabled === false) {
            $captcha = $notifyMeForm.find('.g-recaptcha');
            window.grecaptcha.reset(window.jwsdwRecaptcha[$captcha.attr('id')]);
        }
    }

    /**
     * Method to execute if login was handled successfully
     * @returns {void}
     */
    function notifyMeSuccessCallback () {
        $submitButton.removeAttr('disabled');
        $submitButtonText.removeClass('jwsdw-invisible');
        $submitButtonSpinner.addClass('jws-hidden');

        $('#jwsdw-notifyMePicker').find('.jws-initial-view').addClass('jws-hidden');
        $('#jwsdw-notifyMePicker').find('.jws-success-view').removeClass('jws-hidden');

        // Script Notify Me Button Click (success)
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'ce.misc',
            'eventCategory': 'MISC',
            'eventAction': 'Notify Me',
            'eventLabel': 'success',
            'eventValue': undefined,
            'nonInteraction': false,
            'selection': undefined
        });
    }

    /**
     * Method to execute if login was not handled successfully
     * @param {object} data the response object containing the error
     * @returns {void}
     */
    function notifyMeErorCallback (data) {
        $submitButton.removeAttr('disabled');
        $submitButtonText.removeClass('jwsdw-invisible');
        $submitButtonSpinner.addClass('jws-hidden');

        // jquery ajax returns a wrapped result
        data = data.responseJSON ? data.responseJSON : {};

        if (data.code && data.code === 'GENERAL_ERROR') {
            $formErrorMessage.find('.jws-notificationMessage').text(data.details.message);
            $formErrorMessage.removeClass('jws-hidden');
        }
    }

    // notifyme form submit listener
    $notifyMeForm.on('submit', function (e) {
        formSubmit.call(this, e, notifyMeSuccessCallback, notifyMeErorCallback, notifyMeBeforeSendCallback, notifyMeCompleteCallback);
    });
}(window, jQuery));