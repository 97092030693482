'use strict';

let refinementBar = document.querySelector('.jwsdw-productSearchResult-refinements-contains'),
    tooltip = document.getElementById('jwsdw-refinement-tooltip'),
    tooltipSmall = document.getElementById('jwsdw-refinement-tooltip-small'),
    tooltipCloseBtn = document.getElementById('jwsdw-tooltip-close-btn'),
    tooltipCloseBtnSmall = document.getElementById('jwsdw-tooltip-close-btn-small'),
    tooltipObserver,
    tooltipSmallObserver,
    tooltipOptions = {
        'root': null,
        'rootMargin': "-60px 0px 0px 0px",
        'threshold': 1
    },
    tooltipSmallOptions = {
        'root': null,
        'rootMargin': "0px 0px 0px 0px",
        'threshold': 1
    },
    closedTooltipCookie = window.jwsdwUtil.getCookie('closeTooltip'),
    jwsdwBody = document.querySelector('#jwsdw-body'),
    mediaQueryList = window.matchMedia('(min-width: 900px)');

if (tooltip && !closedTooltipCookie) {
    // show the tooltips by default
    tooltip.classList.add('jwsdw-tooltipActive');
    tooltipSmall.classList.add('jwsdw-tooltipActive');

    tooltipObserver = new IntersectionObserver(function (entries, observer) { // eslint-disable-line no-undef, no-unused-vars
        entries.forEach(function(entry) {
            // If the tooltip is no longer completely intersecting with the viewport, hide it
            if (entry.intersectionRatio < 1) {
                tooltip.classList.add('jwsdw-tooltip-is-not-visible');
            } else {
                tooltip.classList.remove('jwsdw-tooltip-is-not-visible');
            }
        });
    }, tooltipOptions);

    tooltipSmallObserver = new IntersectionObserver(function (entries, observer) { // eslint-disable-line no-undef, no-unused-vars
        entries.forEach(function(entry) {
            // If the tooltip is no longer completely intersecting with the viewport, hide it
            if (entry.intersectionRatio < 0.99) {
                tooltipSmall.classList.add('jwsdw-tooltip-is-not-visible');
            } else {
                tooltipSmall.classList.remove('jwsdw-tooltip-is-not-visible');
            }
        });
    }, tooltipSmallOptions);

    // Close tooltip when close button is clicked
    tooltipCloseBtn.addEventListener('click', function() {
        tooltip.classList.remove('jwsdw-tooltipActive');
        window.jwsdwUtil.setCookie('closeTooltip', 'true', 43200); // 30 days
    });

    // Close small tooltip when close button is clicked
    tooltipCloseBtnSmall.addEventListener('click', function() {
        tooltipSmall.classList.remove('jwsdw-tooltipActive');
        window.jwsdwUtil.setCookie('closeTooltip', 'true', 43200); // 30 days
    });

    // Close tooltip when user clicks anywhere outside of it
    jwsdwBody.addEventListener('click', function(event) {
        if (tooltip.classList.contains('jwsdw-tooltipActive') && !tooltip.classList.contains('jwsdw-tooltip-is-not-visible')) {
            closeTooltip(tooltip, event);
        }
        if (tooltipSmall.classList.contains('jwsdw-tooltipActive') && !tooltip.classList.contains('jwsdw-tooltip-is-not-visible')) {
            closeTooltip(tooltipSmall, event);
        }
    });

    /**
     * @description Method to close tooltip
     * @param {HTMLElement} tooltipElement html element for tooltip
     * @param {Object} event event object
     * @returns {void}
     */
    function closeTooltip(tooltipElement, event) {
        if (!tooltipElement.contains(event.target) && event.target !== tooltipElement) {
            tooltipElement.classList.remove('jwsdw-tooltipActive');
            window.jwsdwUtil.setCookie('closeTooltip', 'true', 43200); // 30 days
        }
    }

    /**
     * @description Adjust tooltip position to stay above the refinement button.
     * @returns {void}
     */
    function adjustTooltipPosition() {
        let refinementBarRect = refinementBar.getBoundingClientRect(),
            tooltipHeight = tooltip.offsetHeight,
            tooltipSmallHeight = tooltipSmall.offsetHeight,
            offset = Math.floor(refinementBarRect.height / 2) + 20, // distance from the button
            topPosition,
            topPositionSmall;

        // if the viewport is S or M, the refinement bar container will be bigger, so we need to recalculate the offset
        if (refinementBarRect.height > 60) {
            offset = Math.floor(refinementBarRect.height);
        }

        // Calculate the top position to keep the tooltip above the button
        topPosition = refinementBarRect.height - tooltipHeight - offset;
        topPositionSmall = refinementBarRect.height - tooltipSmallHeight - offset;

        // Set the top position of the tooltip
        tooltip.style.top = topPosition + 'px';
        tooltipSmall.style.top = topPositionSmall + 'px';
    }

    /**
     * @description Function that adjust tooltip position on resize.
     * @returns {void}
     */
    function handleMediaQueryChange() {
        adjustTooltipPosition();
    }
    mediaQueryList.addListener(handleMediaQueryChange);

    adjustTooltipPosition();
    tooltipObserver.observe(tooltip);
    tooltipSmallObserver.observe(tooltipSmall);
}